import React from 'react';

interface IProps {
  fill: string;
  className?: string;
}

const Energy = ({ fill, className = '' }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.942"
    height="41.333"
    viewBox="0 0 23.942 41.333"
    className={className}
  >
    <path
      id="Path_23894"
      data-name="Path 23894"
      d="M117.384,472.935c-5.487-1.135-5.481-1.133-4.143-6.5,1.17-4.694,2.379-9.378,3.459-14.094.44-1.92,1.367-2.787,3.4-2.714,3.111.114,6.23.011,9.346.036s3.73.826,2.95,3.783c-.7,2.65-1.411,5.3-2.189,8.217,1.4,0,2.519-.048,3.631.009,2.516.129,3.349,1.75,1.9,3.826-1.535,2.2-3.2,4.3-4.808,6.448-4.354,5.816-8.651,11.679-13.124,17.405-.695.888-2.331,1.791-3.274,1.572-1.725-.4-1.292-2.1-.925-3.483C114.872,482.615,116.121,477.779,117.384,472.935Zm-.039,11.626.413.224,15.189-20.311h-6.494l3.234-12.016c-3.179,0-6.015-.057-8.843.051a1.954,1.954,0,0,0-1.448,1.1c-1.358,5.233-2.606,10.495-3.941,15.98l5.65.294Z"
      transform="translate(-112.501 -449.628)"
      fill={fill}
    />
  </svg>
);

export default Energy;
