import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import NutrientValues from './nutrient-values';
import AddToBasketNew from '@features/shop/components/add-to-basket-new/add-to-basket-new';
import HeroHeaderText from './hero-header-text';
import { BarContext } from '@features/bars/context/bar-context';
import { IBars } from '@interfaces/bars';
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';

import styles from './hero.module.scss';

const HeroMain = () => {
  const barData: IBars = useContext(BarContext);
  const {
    dataPlans: productPlans,
    loading: loadingProductPlans,
    isAvailable,
  } = useGetProductPlansData(barData.identifier);

  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)',
  });

  return (
    <div className={styles.heroMain}>
      {isDesktop && <HeroHeaderText />}
      {!loadingProductPlans && (
        <AddToBasketNew
          onComplete={() => {}}
          productPlans={productPlans?.product_plans}
          variant="product"
          isAvailable={isAvailable}
        />
      )}
      <NutrientValues />
      <p className={styles.bottomText}>{barData.header_nutrients_text}</p>
    </div>
  );
};

export default HeroMain;
