import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import Glow from '../icon/glow';
import Energy from '../icon/energy';
import Focus from '../icon/focus';
import { BarContext } from '@features/bars/context/bar-context';

import styles from './hero.module.scss';

const HeroHeaderText = () => {
  const data = useContext(BarContext);

  const isTablet = useMediaQuery({
    query: '(max-width: 991px)',
  });

  const rendericon = () => {
    if (data.identifier === 'energy-bars-15-pack') return <Energy fill={isTablet ? '#ffffff' : data.primary_color} />;
    if (data.identifier === 'glow-bars-15-pack') return <Glow fill={isTablet ? '#ffffff' : data.primary_color} />;
    return <Focus fill={isTablet ? '#ffffff' : data.primary_color} />;
  };

  return (
    <div className={styles.heroText}>
      <h1>
        {rendericon()} {data.header}
      </h1>
      <p>{data.header_text}</p>
      <p className={styles.lastText}>{data.header_text_details}</p>
    </div>
  );
};

export default HeroHeaderText;
