import React, { createContext, useState } from 'react';

import { IBars } from '@interfaces/bars';

interface IProps {
  data: IBars;
  children: JSX.Element;
}

export const BarContext = createContext<IBars>({} as IBars);

export const BarContextProvider = ({ data, children }: IProps) => {
  return <BarContext.Provider value={data}>{children}</BarContext.Provider>;
};
