import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/layout';
import SEO from '@components/seo';
import Bar from '@features/bars/bar';
import { IBars } from '@interfaces/bars';

export const data = graphql`
  query($path: String!) {
    directusBar(path: { eq: $path }) {
      identifier
      bars_title
      bars_description
      path
      primary_color
      secondary_color
      hero_image_1 {
        filename_disk
      }
      hero_image_2 {
        filename_disk
      }
      hero_image_3 {
        filename_disk
      }
      header
      header_text
      header_text_details
      header_nutrients {
        value
        label
      }
      header_nutrients_text
      goodnes_header
      goodnes_text_1
      goodnes_text_big
      goodnes_text_2
      goodnes_image_desktop {
        filename_disk
      }
      goodnes_image_mobile {
        filename_disk
      }
      whats_inside_header
      whats_inside_text
      inside_image_1 {
        filename_disk
      }
      inside_image_2 {
        filename_disk
      }
      inside_image_3 {
        filename_disk
      }
      inside_image_4 {
        filename_disk
      }
      inside_header_1
      inside_header_2
      inside_header_3
      inside_header_4
      inside_text_1
      inside_text_2
      inside_text_3
      inside_text_4
      nutritional_values_items {
        label
        per100g
        per40g
      }
      nutritional_values_label
      vitamins_and_minerals_label
      vitamins_and_minerals_items {
        label
        perServing
        NRV
      }
      ingredients_label
      ingredients_text
      flavour_header
      flavour_items {
        flavourImageUrl
        flavourHeader
        flavourText
        from
        buttonLabel
        path
        color
      }
    }
  }
`;

interface IProps {
  data: {
    directusBar: IBars;
  };
}

const BarsPage = ({ data: { directusBar } }: IProps) => {
  return (
    <Layout>
      <SEO title={directusBar.bars_title} description={directusBar.bars_description} />
      <Bar data={directusBar} />
    </Layout>
  );
};

export default BarsPage;
