import React, { FC, useState } from 'react';
import styles from './collapsible.module.scss';

interface ICollapsibleProps {
  title: string;
  isAccordion?: boolean;
  opened?: boolean;
  handleCollapse: (title: string) => void;
  children: JSX.Element;
}

export const Collapsible: FC<ICollapsibleProps> = ({
  title,
  isAccordion = false,
  opened = false,
  handleCollapse,
  children,
}) => {
  const [isOpened, setIsOpened] = useState(opened);

  const toggleCollapsible = () => {
    if (!isAccordion) {
      setIsOpened(value => !value);
      return;
    }
    handleCollapse(opened ? '' : title);
  };

  const getIsOpenedStyles = () => {
    if (!isAccordion) {
      return isOpened ? styles.opened : '';
    }
    return isAccordion && opened ? styles.opened : '';
  };

  return (
    <div className={styles.collapsibleContainer}>
      <div className={styles.collapsibleHeader} onClick={toggleCollapsible}>
        <div className={styles.collapsibleTitle}>{title}</div>
        <div className={styles.collapsibleIcon}>{(isAccordion && opened) || isOpened ? '-' : '+'}</div>
      </div>
      <div className={`${styles.collapsibleContent} ${getIsOpenedStyles()}`}>{children}</div>
    </div>
  );
};
