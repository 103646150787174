import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { SwiperComponent } from 'vitl-component-library';

import { IBars } from '@interfaces/bars';
import HeroHeaderText from './hero-header-text';
import Image from '@components/image/image';

import styles from './hero.module.scss';

interface IProps {
  data: IBars;
  mobileWidth: number;
  desktopWidth: number;
}

const HeroCarousel = ({ data, mobileWidth, desktopWidth }: IProps) => {
  const isTablet = useMediaQuery({
    query: '(max-width: 991px)',
  });

  const carouselData = [
    { image: data.hero_image_1.filename_disk, slideIndex: 0 },
    { image: data.hero_image_2.filename_disk, slideIndex: 1 },
    { image: data.hero_image_3.filename_disk, slideIndex: 2 },
  ];

  return (
    <div className={styles.carousel}>
      {isTablet && <HeroHeaderText />}
      <div>
        <SwiperComponent paginationType="bullets" spaceBetween={20} slidesPerView={1}>
          {carouselData.map((carouselItem: any) => {
            return (
              <div key={carouselItem.slideIndex} className={styles.item}>
                <Image width={desktopWidth} filename={carouselItem.image} />
              </div>
            );
          })}
        </SwiperComponent>
      </div>
    </div>
  );
};

export default HeroCarousel;
