import React from 'react';

interface IProps {
  fill: string;
  className?: string;
}

const Glow = ({ fill, className = '' }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.449"
    height="32.446"
    viewBox="0 0 32.449 32.446"
    className={className}
  >
    <g id="Group_8489" data-name="Group 8489" transform="translate(-112.5 -449.484)">
      <g id="Group_8487" data-name="Group 8487" transform="translate(112.5 449.484)">
        <path
          id="Path_23895"
          data-name="Path 23895"
          d="M128.724,481.93a.745.745,0,0,1-.735-.616l-2.208-12.664-12.664-2.208a.746.746,0,0,1,0-1.469l12.664-2.207L127.99,450.1a.746.746,0,0,1,1.469,0l2.208,12.665,12.664,2.207a.746.746,0,0,1,0,1.469l-12.664,2.208-2.208,12.664A.744.744,0,0,1,128.724,481.93Zm-11.137-16.223,8.967,1.565a.746.746,0,0,1,.606.606l1.564,8.967,1.564-8.967a.746.746,0,0,1,.606-.606l8.969-1.565-8.967-1.563a.746.746,0,0,1-.608-.606l-1.564-8.967-1.564,8.967a.746.746,0,0,1-.606.606Z"
          transform="translate(-112.5 -449.484)"
          fill={fill}
        />
      </g>
      <g id="Group_8488" data-name="Group 8488" transform="translate(133.097 450.543)">
        <path
          id="Path_23896"
          data-name="Path 23896"
          d="M132.335,461.019a.533.533,0,0,1-.525-.441l-.658-3.773-3.773-.658a.533.533,0,0,1,0-1.05l3.773-.658.658-3.773a.532.532,0,0,1,1.049,0l.659,3.773,3.773.658a.533.533,0,0,1,0,1.05l-3.773.658-.659,3.773A.532.532,0,0,1,132.335,461.019Zm-1.765-5.4,1.134.2a.534.534,0,0,1,.432.434l.2,1.134.2-1.134a.536.536,0,0,1,.434-.434l1.133-.2-1.133-.2a.536.536,0,0,1-.434-.435l-.2-1.133-.2,1.133a.534.534,0,0,1-.432.435Z"
          transform="translate(-126.938 -450.226)"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default Glow;
