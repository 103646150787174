import { graphql, useStaticQuery } from 'gatsby';

export const useBarsFaqText = () => {
  const { directusBarsFaq } = useStaticQuery(graphql`
    query BarsFaqText {
      directusBarsFaq {
        faq_header
        faqs_description
        faqs_items {
          question
          answer
        }
      }
    }
  `);
  return directusBarsFaq;
};
