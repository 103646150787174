import React, { useContext } from 'react';

import { BarContext } from '@features/bars/context/bar-context';
import { IBars } from '@interfaces/bars';
import Image from '@components/image/image';

import styles from './whats-inside.module.scss';

const WhatsInside = () => {
  const data: IBars = useContext(BarContext);

  const carouselData = [
    {
      image: data.inside_image_1.filename_disk,
      header: data.inside_header_1,
      text: data.inside_text_1,
      slideIndex: 1,
    },
    {
      image: data.inside_image_2.filename_disk,
      header: data.inside_header_2,
      text: data.inside_text_2,
      slideIndex: 2,
    },
    {
      image: data.inside_image_3.filename_disk,
      header: data.inside_header_3,
      text: data.inside_text_3,
      slideIndex: 3,
    },
    {
      image: data.inside_image_4.filename_disk,
      header: data.inside_header_4,
      text: data.inside_text_4,
      slideIndex: 4,
    },
  ];

  return (
    <div className={styles.whatsInside} style={{ backgroundColor: data.secondary_color }}>
      <div className={styles.container}>
        <h2>{data.whats_inside_header}</h2>
        <hr />
        <p>{data.whats_inside_text}</p>
      </div>

      <div className={styles.scroller}>
        <div className={styles.scrollDown} />
        {carouselData.map((carouselItem: any) => (
          <div
            key={carouselItem.slideIndex}
            className={`${styles.wrap} ${carouselItem.slideIndex === 1 ? styles.first : ''}`}
          >
            <Image width={800} filename={carouselItem.image} />
            <h4>{carouselItem.header}</h4>
            <p>{carouselItem.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatsInside;
