import React from 'react';

import JoinTheCommunity from '@components/join-the-community/join-the-community';
import StillHaveQuestion from '@components/still-have-question/still-have-question';
import BarProducts from './components/products/products';
import NutritionInfoSection from './components/nutrition-info-section/nutrition-info-section';
import WhatsInside from './components/whats-inside/whats-inside';
import OnTheGo from './components/on-the-go/on-the-go';
import BarHero from './components/hero/BarHero';
import { IBars } from '@interfaces/bars';
import { useBarsFaqText } from '@hooks/directusHooks/barsFAQ';
import { BarContextProvider } from './context/bar-context';

import styles from './bar.module.scss';

interface IProps {
  data: IBars;
}

const Bar = ({ data }: IProps) => {
  const faqText = useBarsFaqText();

  return (
    <BarContextProvider data={data}>
      <div className={styles.bars}>
        <BarHero />
        <OnTheGo />
        <WhatsInside />
        <NutritionInfoSection />
        <BarProducts />
        <StillHaveQuestion accordion={faqText.faqs_items} description={faqText.faqs_description} />
        <JoinTheCommunity />
      </div>
    </BarContextProvider>
  );
};

export default Bar;
