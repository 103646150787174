import React, { useContext } from 'react';

import { IHeaderNutrients } from '@interfaces/bars';
import { BarContext } from '@features/bars/context/bar-context';

import styles from './hero.module.scss';

const NutrientValues = () => {
  const data = useContext(BarContext);

  return (
    <div className={styles.nutrientValues}>
      {data.header_nutrients.map((item: IHeaderNutrients) => (
        <div key={item.label} className={styles.value}>
          <span className={styles.top}>{item.value}</span>
          <p>{item.label}</p>
        </div>
      ))}
    </div>
  );
};

export default NutrientValues;
