import React, { useContext } from 'react';

import { BarContext } from '@features/bars/context/bar-context';
import { NutritionInfo } from '../nutrition-info/NutritionInfo';
import Shape from '../shape/shape';

import styles from './nutrition-info-section.module.scss';

const NutritionInfoSection = () => {
  const data = useContext(BarContext);

  return (
    <div className={styles.nutritions} style={{ background: data.primary_color }}>
      <Shape fill={data.primary_color} className={styles.image} />
      <div className={styles.container}>
        <NutritionInfo data={data} />
      </div>
    </div>
  );
};

export default NutritionInfoSection;
