import React, { useContext } from 'react';

import { BarContext } from '@features/bars/context/bar-context';
import BarProduct from '../bar-product/bar-product';

import styles from './products.module.scss';

const BarProducts = () => {
  const data = useContext(BarContext);
  return (
    <div className={styles.products}>
      <div className={styles.container}>
        <h2>{data.flavour_header}</h2>
        <hr />
        <div className={styles.productWrap}>
          {data.flavour_items.map(item => (
            <BarProduct data={item} key={item.flavourImageUrl} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BarProducts;
