import React, { FC } from 'react';
import { BARS_DATA } from '../../../utils/data';
import styles from './ingredients.module.scss';

interface IIngredientsProps {
  data: string;
}

export const Ingredients: FC<IIngredientsProps> = ({ data }) => {
  return <div className={styles.ingredientsInfo} dangerouslySetInnerHTML={{ __html: data }} />;
};
