import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import { BarContext } from '@features/bars/context/bar-context';
import { IBars } from '@interfaces/bars';

import styles from './on-the-go.module.scss';

const OnTheGo = () => {
  const data: IBars = useContext(BarContext);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  return (
    <div className={styles.onTheGo}>
      <div className={styles.container}>
        <div className={styles.left}>
          <h2>{data.goodnes_header}</h2>
          <hr />
          <p>{data?.goodnes_text_1}</p>
          <p>{data.goodnes_text_big}</p>
          <p dangerouslySetInnerHTML={{ __html: data?.goodnes_text_2 }} />
        </div>
        <div className={styles.right}>
          {isMobile ? (
            <img src={`${process.env.GATSBY_MEDIA_URL}${data.goodnes_image_mobile.filename_disk}?w=600`} />
          ) : (
            <img src={`${process.env.GATSBY_MEDIA_URL}${data.goodnes_image_desktop.filename_disk}?w=600`} />
          )}
        </div>
      </div>
    </div>
  );
};

export default OnTheGo;
