import React, { FC, useContext, useEffect, useState } from 'react';

import { BarContext } from '@features/bars/bar';
import { Collapsible } from '@components/collapsible/Collapsible';
import { Ingredients } from './ingredients/Ingredients';
import { NutritionalValues } from './nutritional-values/NutritionalValues';
import { VitaminsAndMinerals } from './vitamins-and-minerals/VitaminsAndMinerals';
import { IBars } from '@interfaces/bars';

import styles from './nutrition-info.module.scss';

interface IProps {
  data: IBars;
}

export const NutritionInfo = ({ data }: IProps) => {
  const [opened, setOpened] = useState(data.nutritional_values_label);

  const handleCollapse = (title: string) => setOpened(title);

  const nutritionalHeaderLabels = ['', 'Per 100g', 'Per bar(40g)'];
  const vitaminsMineralsLabels = ['', 'Per serving 40g', 'NRV(%)'];

  return (
    <div className={styles.nutritionInfoContainer}>
      <Collapsible
        isAccordion
        title={data.nutritional_values_label}
        opened={opened === data.nutritional_values_label}
        handleCollapse={handleCollapse}
      >
        <NutritionalValues data={data.nutritional_values_items} headerLabels={nutritionalHeaderLabels} />
      </Collapsible>
      <div className={styles.horizontalLine} />
      <Collapsible
        isAccordion
        title={data.vitamins_and_minerals_label}
        opened={opened === data.vitamins_and_minerals_label}
        handleCollapse={handleCollapse}
      >
        <VitaminsAndMinerals data={data.vitamins_and_minerals_items} headerLabels={vitaminsMineralsLabels} />
      </Collapsible>
      <div className={styles.horizontalLine} />
      <Collapsible
        isAccordion
        title={data.ingredients_label}
        opened={opened === data.ingredients_label}
        handleCollapse={handleCollapse}
      >
        <Ingredients data={data.ingredients_text} />
      </Collapsible>
    </div>
  );
};
