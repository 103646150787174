import React from 'react';
import { Link } from '@reach/router';

import { IFlavour } from '@interfaces/bars';
import Image from '@components/image/image';
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';

import styles from './product.module.scss';
import { OUT_OF_STOCK } from '@src/constants/messages';

interface IProps {
  data: IFlavour;
}

const getSkuForBarProduct = (buttonLabel: string) => {
  switch (buttonLabel) {
    case 'Shop Glow':
      return 'glow-bars-15-pack';
    case 'Shop Energy':
      return 'energy-bars-15-pack';
    case 'Shop Focus':
      return 'focus-bars-15-pack';
    default:
      return '';
  }
};

const BarProduct = ({ data }: IProps) => {
  const { price, isAvailable } = useGetProductPlansData(getSkuForBarProduct(data.buttonLabel));

  return (
    <div className={styles.product}>
      <div className={styles.image} style={{ background: data.color }}>
        <Image width={320} filename={data.flavourImageUrl} />
      </div>
      <h5>{data.flavourHeader}</h5>
      <p>{data.flavourText}</p>
      <p className={styles.price}>{isAvailable ? `${data.from} ${price}` : OUT_OF_STOCK}</p>
      <Link className={styles.buttonPrimary} to={data.path}>
        {data.buttonLabel}
      </Link>
    </div>
  );
};

export default BarProduct;
