import React from 'react';

interface IProps {
  fill: string;
  className?: string;
}

const Focus = ({ fill, className = '' }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45.485"
    height="45.485"
    viewBox="0 0 45.485 45.485"
    className={className}
  >
    <g id="Group_8490" data-name="Group 8490" transform="translate(-112.5 -448.547)">
      <path
        id="Path_23897"
        data-name="Path 23897"
        d="M156.025,469.328h-3.007A17.886,17.886,0,0,0,137.2,453.514v-3a1.962,1.962,0,1,0-3.923,0v3a17.891,17.891,0,0,0-15.813,15.813h-3a1.962,1.962,0,1,0,0,3.923h3a17.891,17.891,0,0,0,15.813,15.813v3.007a1.962,1.962,0,0,0,3.923,0v-3.007a17.886,17.886,0,0,0,15.813-15.813h3.007a1.962,1.962,0,0,0,0-3.923Zm-10,3.923h4.364a15.3,15.3,0,0,1-13.18,13.18v-4.363a1.962,1.962,0,1,0-3.923,0v4.363a15.3,15.3,0,0,1-13.178-13.18h4.363a1.962,1.962,0,0,0,0-3.923H120.1a15.3,15.3,0,0,1,13.178-13.18v4.363a1.962,1.962,0,1,0,3.923,0v-4.363a15.3,15.3,0,0,1,13.18,13.18h-4.364a1.962,1.962,0,0,0,0,3.923Z"
        transform="translate(0 0)"
        fill={fill}
      />
      <circle
        id="Ellipse_619"
        data-name="Ellipse 619"
        cx="2.399"
        cy="2.399"
        r="2.399"
        transform="translate(132.844 468.891)"
        fill={fill}
      />
    </g>
  </svg>
);

export default Focus;
