import React from 'react';

import { IVitaminsMinerals } from '@interfaces/bars';
import styles from './vitamins-and-minerals.module.scss';

interface IProps {
  data: IVitaminsMinerals[];
  headerLabels: string[];
}

export const VitaminsAndMinerals = ({ data, headerLabels }: IProps) => {
  return (
    <table className={styles.vitaminsAndMineralsTable}>
      <tr>
        {headerLabels.map(label => (
          <th key={label} className={styles.textAlignRight}>
            {label}
          </th>
        ))}
      </tr>
      {data.map(row => (
        <tr key={row.label}>
          <td className={styles.textAlignLeft}>{row.label}</td>
          <td className={styles.textAlignRight}>{row.perServing}</td>
          <td className={styles.textAlignRight}>{row.NRV}</td>
        </tr>
      ))}
    </table>
  );
};
