import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import { BarContext } from '@features/bars/context/bar-context';
import Shape from '../shape/shape';
import HeroCarousel from './hero-carousel';
import HeroMain from './hero-main';

import styles from './hero.module.scss';
import { IBars } from '@interfaces/bars';

const BarHero = () => {
  const data: IBars = useContext(BarContext);
  const isTablet = useMediaQuery({
    query: '(max-width: 991px)',
  });

  return (
    <div className={styles.hero}>
      <div
        className={styles.left}
        style={{
          background: isTablet
            ? `linear-gradient(to bottom, ${data.primary_color} 69%, ${data.secondary_color} 31%)`
            : data.primary_color,
        }}
      >
        <HeroCarousel data={data} mobileWidth={360} desktopWidth={500} />
      </div>
      <div className={styles.right} style={{ background: data.secondary_color }}>
        <HeroMain />
      </div>
      {isTablet && <Shape fill={data.secondary_color} className={styles.shape} />}
    </div>
  );
};

export default BarHero;
