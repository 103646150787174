import React from 'react';

import { INutritionalValues } from '@interfaces/bars';

import styles from './nutritional-values.module.scss';

interface IProps {
  data: INutritionalValues[];
  headerLabels: string[];
}

export const NutritionalValues = ({ data, headerLabels }: IProps) => {
  return (
    <table className={styles.nutritionalValuesTable}>
      <tr>
        {headerLabels.map(label => (
          <th key={label} className={styles.textAlignRight}>
            {label}
          </th>
        ))}
      </tr>
      {data.map(row => (
        <tr key={row.label}>
          <td className={styles.textAlignLeft}>{row.label}</td>
          <td className={styles.textAlignRight}>{row.per100g}</td>
          <td className={styles.textAlignRight}>{row.per40g}</td>
        </tr>
      ))}
    </table>
  );
};
